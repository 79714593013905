<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addtype">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="title" :label="$t('jc.biaoti')"></el-table-column>
      <!--<el-table-column
        prop="content"
        :label="$t('jc.neirong')"
      ></el-table-column>-->
      <el-table-column prop="xtname" :label="$t('jc.jcfl')"></el-table-column>
      <el-table-column
        prop="createtime"
        :label="$t('cjsj')"
        :formatter="dateFormat"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加产品分类弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="180px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jc.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong')" prop="content">
          <el-input type="textarea" v-model="infoform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(英语)'" prop="title">
          <el-input v-model="infoform.title_en"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(英语)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_en"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(繁体)'" prop="title">
          <el-input v-model="infoform.title_hk"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(繁体)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_hk"></el-input>
        </el-form-item>

         <el-form-item :label="$t('jc.biaoti') + '(泰语)'" prop="title">
          <el-input v-model="infoform.title_th"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(泰语)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_th"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(韩语)'" prop="title">
          <el-input v-model="infoform.title_kor"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(韩语)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_kor"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(日语)'" prop="title">
          <el-input v-model="infoform.title_jp"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(日语)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_jp"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(法语)'" prop="title">
          <el-input v-model="infoform.title_fra"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(法语)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_fra"></el-input>
        </el-form-item> 
        <el-form-item :label="$t('jc.biaoti') + '(西班牙)'" prop="title">
          <el-input v-model="infoform.title_spa"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(西班牙)'" prop="content">
          <el-input type="textarea" v-model="infoform.content_spa"></el-input>
        </el-form-item> 
        <el-form-item :label="$t('jc.jcfl')" prop="typeids">
          <el-select
            multiple
            v-model="infoform.typeids"
            :placeholder="$t('qxz')"
          >
            <el-option
              v-for="item in helptype"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑产品分类弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="editform"
        label-width="180px"
        ref="editform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jc.biaoti')" prop="title">
          <el-input v-model="editform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong')" prop="content">
          <el-input type="textarea" v-model="editform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(繁体)'" prop="title">
          <el-input v-model="editform.title_hk"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(繁体)'" prop="content">
          <el-input type="textarea" v-model="editform.content_hk"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(英文)'" prop="title">
          <el-input v-model="editform.title_en"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(英文)'" prop="content">
          <el-input type="textarea" v-model="editform.content_en"></el-input>
        </el-form-item>
       
        <el-form-item :label="$t('jc.biaoti') + '(泰语)'" prop="title">
          <el-input v-model="editform.title_th"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(泰语)'" prop="content">
          <el-input type="textarea" v-model="editform.content_th"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(韩语)'" prop="title">
          <el-input v-model="editform.title_kor"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(韩语)'" prop="content">
          <el-input type="textarea" v-model="editform.content_kor"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(日语)'" prop="title">
          <el-input v-model="editform.title_jp"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(日语)'" prop="content">
          <el-input type="textarea" v-model="editform.content_jp"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.biaoti') + '(法语)'" prop="title">
          <el-input v-model="editform.title_fra"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(法语)'" prop="content">
          <el-input type="textarea" v-model="editform.content_fra"></el-input>
        </el-form-item> 
        <el-form-item :label="$t('jc.biaoti') + '(西班牙)'" prop="title">
          <el-input v-model="editform.title_spa"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jc.neirong') + '(西班牙)'" prop="content">
          <el-input type="textarea" v-model="editform.content_spa"></el-input>
        </el-form-item> 

        <el-form-item :label="$t('jc.jcfl')" prop="typeids">
          <el-select
            multiple
            v-model="editform.typeids"
            :placeholder="$t('qxz')"
          >
            <el-option
              v-for="item in helptype"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: true,
      // 教程分类
      helptype: [],
      // 添加教程数据对象
      infoform: {
        title: '',
        content: '',
        title_en: '',
        content_en: '',
        title_kor: '',
        content_kor: '',
        title_fra: '',
        content_fra: '',
        title_jp: '',
        content_jp: '',
        title_spa: '',
        content_spa: '',
        title_hk: '',
        content_hk: '',
        title_th: '',
        content_th: '',
        typeids: null
      },
      dialogvisible: false,
      formrules: {
        title: [{ required: true, message: '请填写标题', trigger: 'blur' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
        typeids: [
          { required: true, message: '请选择教程分类', trigger: 'select' }
        ]
      },
      // 编辑教程数据对象
      editform: {
        title: '',
        content: '',
        title_en: '',
        content_en: '',
        title_kor: '',
        content_kor: '',
        title_fra: '',
        content_fra: '',
        title_jp: '',
        content_jp: '',
        title_spa: '',
        content_spa: '',
        title_hk: '',
        content_hk: '',
        title_th: '',
        content_th: '',
        typeids: null,
        id: ''
      },
      editdialogvisible: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/help/helplist', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.loading = false
      this.total = +data.data.count
    },
    // 添加分类弹窗
    async addtype() {
      this.dialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/help/addhelpinfo')
      if (data) {
        if (data.code === 200) {
          this.helptype = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.infoform.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 提交添加分类
    submit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/help/addhelpinfo',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            //this.getlist()
            location.reload();
            this.$message.success(data.msg)
            this.handleClose()
          } else {
            this.$message.error(data.msg)
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/help/edithelpinfo/id/' + id)
      
      this.editform.title = data.data.info.title
      this.editform.content = data.data.info.content
      this.editform.title_hk = data.data.info.title_hk
      this.editform.content_hk = data.data.info.content_hk
      this.editform.title_en = data.data.info.title_en
      this.editform.content_en = data.data.info.content_en
      this.editform.title_kor = data.data.info.title_kor
      this.editform.content_kor = data.data.info.content_kor
      this.editform.title_jp = data.data.info.title_jp
      this.editform.content_jp = data.data.info.content_jp
      this.editform.title_fra = data.data.info.title_fra
      this.editform.content_fra = data.data.info.content_fra
      this.editform.title_th = data.data.info.title_th
      this.editform.content_th = data.data.info.content_th
      this.editform.title_spa = data.data.info.title_spa
      this.editform.content_spa = data.data.info.content_spa
      this.editform.typeids = data.data.info.typeids
      this.editform.id = data.data.info.id
      this.helptype = data.data.types
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/help/edithelpinfo',
          this.editform
        )
        if (data) {
          if (data.code === 200) {
            //this.getlist()
            this.$message.success(this.getlang(data.msg))
            location.reload();
            this.editclose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.infoform = ''
      //this.$refs.editform.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: '确定',
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/help/delhelpinfo', {
          id
        })

        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
</style>
